import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { useImageForData } from "../../hooks/useAllImages";

const AdSection = ({ title, description, imageName, link }) => (
  <div className="bg-amber-500 py-4">
    <div className="bg-amber-500">
      <div className="bg-amber-500 rounded-lg max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex gap-12 lg:items-center lg:justify-between">
        <div className="lg:w-1/2">
          <h2 className="text-3xl font-extrabold text-gray-900">
            <span className="block">{title}</span>
          </h2>
          <p className="mt-2 text-base text-gray-900 text-left">
            {description}
          </p>
        </div>
        <div className="mt-8 lg:mt-0 lg:w-1/2">
          <a href={link} className="h-32">
            <GatsbyImage
              image={useImageForData(imageName)}
              alt={title}
              className="w-full h-full object-cover"
              imgClassName="w-full h-full object-cover"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
);

export default AdSection;

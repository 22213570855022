import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { useImageForData } from "../../hooks/useAllImages";

const FAQsSection = ({
  title,
  description,
  linkText,
  linkPath,
  imgName,
  children,
}) => (
  <div className="bg-white">
    <div className="max-w-7xl mx-auto pt-16 px-4 sm:px-6 lg:pt-24 lg:px-8">
      <div className="lg:grid lg:grid-cols-3 lg:gap-8">
        <div>
          <h2 className="text-3xl font-extrabold text-gray-900">{title}</h2>
          <p className="mt-4 text-lg text-gray-500">
            {description && <>{description}{" "} </>}
            {linkText && (
              <Link
                to={linkPath}
                className="font-medium text-amber-500 hover:text-amber-400"
              >
                {linkText}
              </Link>
            )}
          </p>
          <div className="mt-4 text-center lg:text-left">
            <GatsbyImage
              image={useImageForData(imgName)}
              alt={title}
              className="w-[28%] lg:w-[98%] xl:w-[83%] h-full object-center"
              imgClassName="w-[28%] lg:w-[98%] xl:w-[83%] h-full object-center"
            />
          </div>
        </div>
        <div className="mt-12 lg:mt-0 lg:col-span-2 pb-2">
          <dl className="space-y-12">{children}</dl>
        </div>
      </div>
    </div>
  </div>
);

export default FAQsSection;

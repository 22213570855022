import React from "react";

const FAQItem = ({ question, answer }) => (
  <div key={question}>
    <dt className="text-lg leading-6 font-medium text-gray-900">{question}</dt>
    <dd className="mt-2 text-base text-gray-500">{answer}</dd>
  </div>
);

export default FAQItem;

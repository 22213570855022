import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { useImageForData } from "../../hooks/useAllImages";

const MainSection = ({
  title,
  imageName,
  highlightedTitle,
  description,
  firstBtnText,
  firstBtnLink,
  secondBtnText,
  secondBtnLink,
  brightText,
}) => (
  <main>
    <div className="relative bg-white overflow-hidden">
      <div className="max-w-7xl mx-auto">
        <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 xl:max-w-2xl xl:w-full xl:pb-28 xl:pb-32">
          <svg
            className="hidden xl:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
            fill="currentColor"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            aria-hidden="true"
          >
            <polygon points="50,0 100,0 50,100 0,100" />
          </svg>

          <main className="pt-16 lg:pt-28 mx-auto max-w-7xl px-4 sm:px-6 xl:px-8">
            <div className="sm:text-center xl:text-left xl:mr-4">
              <h1 className="text-4xl tracking-tight font-bold text-gray-900 sm:text-5xl">
                <span className="block xl:inline">{title}</span>{" "}
                <span className="block text-amber-500 xl:inline">
                  {highlightedTitle}
                </span>
              </h1>
              <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl xl:mx-0">
                {description}
              </p>
              {(firstBtnText || secondBtnText) && (
                <div className="mt-5 mx-auto sm:flex sm:justify-center xl:justify-start md:mt-8">
                  {firstBtnText && (
                    <div className="rounded-md shadow">
                      <Link
                        to={firstBtnLink}
                        className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-amber-500 hover:bg-amber-600 md:py-4 md:text-lg md:px-10"
                      >
                        {firstBtnText}
                      </Link>
                    </div>
                  )}
                  {secondBtnText && (
                    <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
                      <Link
                        to={secondBtnLink}
                        className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-amber-500 bg-white hover:bg-gray-50 md:py-4 md:text-lg md:px-10"
                      >
                        {secondBtnText}
                      </Link>
                    </div>
                  )}
                </div>
              )}
            </div>
          </main>
        </div>
      </div>
      <div className="xl:absolute xl:inset-y-0 xl:right-0 xl:w-1/2 ">
        <GatsbyImage
          image={useImageForData(imageName)}
          alt={title}
          className="w-full h-full object-cover xl:left-[4%] 2xl:left-[2%] 2xl:max-w-[600px]"
        />
      </div>
    </div>
    <div className="py-2 uppercase bg-amber-500 text-gray-800 text-center">
      {brightText}
    </div>
  </main>
);

export default MainSection;

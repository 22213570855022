import React from "react";

const FeaturesBigItem = ({
  name,
  description,
  Icon,
}) => (
  <div key={name} className="relative">
    <dt>
      <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-amber-500 text-white">
        <Icon className="h-6 w-6" aria-hidden="true" />
      </div>
      <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
        {name}
      </p>
    </dt>
    <dd className="mt-2 ml-16 text-base text-gray-500">
      {description}
    </dd>
  </div>
);

export default FeaturesBigItem;

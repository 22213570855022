import * as React from "react";
import { CheckIcon, GlobeAltIcon, WifiIcon } from "@heroicons/react/outline";
import { LockClosedIcon } from "@heroicons/react/solid";

import PageTitle from "../components/elements/PageTitle";
import Footer from "../components/sections/Footer";
import MainSection from "../components/sections/MainSection";
import FeaturesBigSection from "../components/sections/FeaturesBigSection";
import FAQsSection from "../components/sections/FAQsSection";
import FAQItem from "../components/elements/FAQItem";
import ShortContactSection from "../components/sections/ShortContactSection";
import FeaturesBigItem from "../components/elements/FeatureBigItem";
import AdSection from "../components/sections/AdSection";
import Header from "../components/sections/Header";

const IndexEnPage = () => (
  <div className="bg-white niramit-regular">
    <PageTitle pageTitle={"AxxPay"} description={``} />
    <Header lang="en" />
    <MainSection
      title="AxxPay"
      imageName="axxpay-main-image-high-resolution.png"
      highlightedTitle="for quick and safe payments"
      description="The business is always in your pocket, register your sales and pay with the smartphone you always carry with you."
      firstBtnText="Contact us"
      firstBtnLink="contacts"
      secondBtnText="Download from Google Play"
      secondBtnLink="/"
      brightText="The business is always in your pocket, register your sales and start receiving payments with the smartphone you always carry with you"
    />
    <FeaturesBigSection
      title="Get paid easily and conveniently anywhere and anytime"
      description=""
    >
      <FeaturesBigItem
        name="Full security"
        description="The solution is certified according to EMV and PCI security standards and approved by Swedish banks, VISA, Mastercard and PCI."
        Icon={LockClosedIcon}
      />
      <FeaturesBigItem
        name="Reduced cost"
        description="No additional hardware is needed as your smart- phone becomes a payment terminal."
        Icon={CheckIcon}
      />
      <FeaturesBigItem
        name="Flexibility and mobility"
        description="Charge your customers anywhere, anytime. You can start selling & accept card payments. Fast installation and onboarding."
        Icon={WifiIcon}
      />
      <FeaturesBigItem
        name="Environmentally friendly"
        description="No extra power consumption and less waste."
        Icon={GlobeAltIcon}
      />
      {/* <FeaturesBigItem
        title="Always in the loop"
        description="Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit
              ex obcaecati natus eligendi delectus, cum deleniti sunt in labore
              nihil quod quibusdam expedita nemo."
        imgName="axxpay-image.png"
        features={[
          {
            name: "Competitive exchange rates",
            description:
              "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
            icon: GlobeAltIcon,
          },
          {
            name: "Competitive exchange rates",
            description:
              "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
            icon: GlobeAltIcon,
          },
          {
            name: "Competitive exchange rates",
            description:
              "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
            icon: GlobeAltIcon,
          },
        ]}
        reverse={true}
      /> */}
    </FeaturesBigSection>
    <AdSection
      title="Your smartphone becomes your payment terminal"
      description="With AxxPay, any Android 8+ device can be your payment terminal. In minutes you can set up wireless online payments on your preferred device and easily integrate it into your business."
      imageName="axxpay_googleplay.png"
      link=""
    />
    <FAQsSection
      title="Frequently asked questions"
      description="Read about requirements"
      linkText="here"
      linkPath="/en/requirements/"
      imgName="axxpay-hand.png"
    >
      <FAQItem
        question="Which payment methods are accepted?"
        answer="AxxPay accepts the most common payment methods: such as Visa, Mastercard also Apple Pay, Google Pay and Samsung Pay."
      />
      <FAQItem
        question="Can I cancel transaction and make a refund?"
        answer="No problem! AxxPay supports cancellation of payment or refund to your customer’s card."
      />
      <FAQItem
        question="PIN-code is needed?"
        answer="We integrated certified PIN-code module so you can take payments of any amount."
      />
      <FAQItem
        question="Can I integrate AxxPay with my loyalty program? "
        answer="You can seamlessly integrate AxxPay with your existing solutions (loyalty programs, ongoing discounts or other payment systems installed), or use AxxPay independently."
      />
      <FAQItem
        question="When can I get money on my company bank account?"
        answer="The money may end up in your company account the next banking day, depending on your agreement with your acquiring bank."
      />
      <FAQItem
        question="Can I use regular payment terminal instead of Android device?"
        answer="Yes, you can."
      />  
    </FAQsSection>
    <ShortContactSection
      title="Contact us"
      description="Leave your question in this form"
      subjectMail="Short contact"
      nameLabel="Name"
      emailLabel="Email"
      phoneLabel="Phone"
      messageLabel="Message"
      submitButton="Submit"
      submitButtonSuccess="Sent"
      alertTitle="Thank you!"
      alertText="We`ve received your message. Someone from our team will contact you soon"
    />
    <Footer lang="en" />
  </div>
);

export default IndexEnPage;
